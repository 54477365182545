

























































































































































.modal::v-deep {
  .modal__wrapper {
    max-width: 836px;
    width: 100%;
  }
}
